import React from "react";

const DetailsTable = ({ results }) => (
  <div className="details">
    <table className="w-full text-left text-sm bg-gray-900 odd:bg-slate-900 text-gray-200 border border-gray-800 rounded">
      <thead className="bg-gray-800 text-gray-400">
        <tr>
          <th className="px-4 py-2">#</th>
          <th className="px-2 py-2">Competitor</th>
          <th className="px-4 py-2 hidden">Time</th>
        </tr>
      </thead>
      <tbody className="">
        {results.map((result, index) => (
          <tr key={index}>
            <td className="px-4 py-2 border-t text-white border-gray-800">
              {result.lane_boat_number}
            </td>
            <td className="px-4 py-2 border-t border-gray-800">
              {result.competitor.name_long}
            </td>
            <td className="px-4 py-2 border-t border-gray-800 hidden">
              {result.total_time}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default DetailsTable;
