import React, { useEffect, useState } from "react";
import ScheduleItem from "./ScheduleItem";
import ProvderLogo from "../assets/images/clockcaster-logo-white-small.png";

const Sidebar = () => {
  const [schedule, setSchedule] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/static/media/cc_event.json");
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setSchedule(data.schedule);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <aside className="w-full md:w-96 bg-gray-950 p-4 h-full max-h-[calc(100vh-3rem)] overflow-y-scroll no-scrollbar text-white sticky top-0">
      <h1 id="" className="text-center text-3xl">
        Event Lineup
      </h1>
      <a
        href="https://clockcaster.com/e/59"
        target="_blank"
        className="cursor-pointer"
      >
        <p
          id=""
          className="flex items-center justify-center gap-2 text-sm text-gray-300 mb-4"
        >
          Powered By:{""}
          <img className="h-5 object-contain" src={ProvderLogo} alt="" />
        </p>
      </a>
      <ul id="schedule-list" className="divide-y divide-gray-800">
        {error ? (
          <p className="text-gray-400 text-center">
            Unfortunately, schedule data is not available for this event.
          </p>
        ) : (
          schedule.map((item, index) => (
            <ScheduleItem key={index} item={item} />
          ))
        )}
      </ul>
    </aside>
  );
};

export default Sidebar;
