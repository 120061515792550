import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Hls from "hls.js";

const VideoPlayer = () => {
  const videoNode = useRef(null);
  const playerRef = useRef(null);
  const videoSrc =
    "https://vz-a5b6f817-578.b-cdn.net/1a793893-1b6f-40da-b5d5-288d4e311785/playlist.m3u8"; // Replace with your live stream URL

  useEffect(() => {
    if (!videoNode.current) return;

    // Initialize Video.js player
    const player = videojs(videoNode.current, {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      liveui: true,
      playsinline: true,
      preload: "auto",
      controlBar: {
        liveDisplay: true,
      },
    });
    playerRef.current = player;

    // Attach HLS.js if supported
    if (Hls.isSupported()) {
      const hls = new Hls({
        liveDurationInfinity: true, // Treat the stream as infinite
        enableWorker: true, // Use HLS.js worker for better performance
      });
      hls.loadSource(videoSrc);
      hls.attachMedia(player.tech().el()); // Attach to Video.js player

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        player.play().catch((error) => {
          console.error("Video playback failed:", error);
        });
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.warn("Network error detected, trying to recover...");
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.warn("Media error detected, trying to recover...");
              hls.recoverMediaError();
              break;
            default:
              console.error("Fatal error - destroying HLS instance.");
              hls.destroy();
              break;
          }
        }
      });

      return () => {
        hls.destroy(); // Cleanup HLS.js
      };
    } else if (videoNode.current.canPlayType("application/vnd.apple.mpegurl")) {
      // Fallback for Safari
      player.src({ src: videoSrc, type: "application/vnd.apple.mpegurl" });
    } else {
      console.error("HLS not supported in this browser.");
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [videoSrc]);

  return (
    <div className="relative ">
      <iframe
        title="Christmas Regatta"
        src="https://iframe.mediadelivery.net/embed/350891/07cfd809-de0a-4c16-8ada-bf17590ad6bb?autoplay=true&loop=false&muted=false&preload=true&responsive=false"
        loading="lazy"
        className="w-full aspect-[16/9]"
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        allowfullscreen="true"
      ></iframe>
      {/* <video
        ref={videoNode}
        className="video-js vjs-default-skin absolute top-0 left-0 aspect-video"
        playsInline
        preload="auto"
        autoPlay
        controls
      /> */}
    </div>
  );
};

export default VideoPlayer;
