import "./index.css";
import trclogo from "./assets/images/TRC-short-white-santa.png";
import eventLogo from "./assets/images/lbra_Logo.png";
import Sidebar from "./components/Sidebar";
import VideoPlayer from "./components/VideoPlayer";

function App() {
  return (
    <div className="App bg-gray-900">
      <header className="bg-gray-900 w-full h-12 text-slate-50 flex items-center px-4">
        <img src={trclogo} alt="logo" className="h-8" />
        <ul className="flex hidden">
          <a className="" href="index.html">
            <li className="ml-4 px-2 bg-red-600 rounded-md hover:bg-red-700">
              Watch Live
            </li>
          </a>
          <a className="ml-4" href="index.html">
            <li className=" rounded-md">Home</li>
          </a>
          <a className="ml-4" href="index.html">
            <li className=" rounded-md">Library</li>
          </a>
        </ul>

        <div className="ml-auto flex"></div>
      </header>
      <main className="flex flex-col md:flex-row flex-1">
        <div className="flex-1 bg-gray-900 flex flex-col">
          <VideoPlayer />
          <div className="w-full p-3 text-white">
            <div className="flex gap-4 items-center">
              <div className="w-14 aspect-square rounded-sm overflow-hidden">
                <img
                  alt="logo"
                  src={eventLogo}
                  className="w-full h-full object-cover"
                />
              </div>
              <div>
                <h1 className="text-2xl font-light">
                  Long Beach Bill Lockyer Christmas Regatta
                </h1>
                <p className="font-thin">
                  Sunday, December 8, 2024 | Alamitos Bay-Marine Stadium Olympic
                  Venue | Long Beach, CA USA
                </p>
                <div className="gap-2 flex">
                  <span className="px-2 py-1 bg-gray-700 rounded-md">
                    On-Demand
                  </span>
                  <span className="px-2 py-1 bg-gray-700 rounded-md">
                    Rowing
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidebar />
      </main>
    </div>
  );
}

export default App;
